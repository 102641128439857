import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/wild-things-next/app/_assets/components/layout/CookiesApproval.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/vercel/path0/wild-things-next/app/_assets/components/layout/NavBar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wild-things-next/app/_assets/images/logo-small.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wild-things-next/node_modules/@fontsource/nunito/index.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wild-things-next/node_modules/@fontsource/josefin-sans/index.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wild-things-next/node_modules/@fontsource/aclonica/index.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wild-things-next/app/_assets/styles/scss/main.scss");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/wild-things-next/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/wild-things-next/node_modules/next/dist/client/image-component.js");
